<template>
  <section>
    <transition name="sf-fade" :key="showVerification" mode="out-in">
      <h4
        key="thanks"
        class="subscribe__message"
        v-if="showVerification"
      >
        {{ $t('Thank you, we will notify you when it\'s back!') }}
      </h4>
      <h4
        key="subscribe"
        class="subscribe__message"
        v-if="!showVerification"
      >
        {{ this.soldOut }}
      </h4>
    </transition>
    <div class="subscribe__wrapper">
      <SfInput
        type="email"
        v-model="enteredEmail"
        class="sf-input--filled subscribe__input"
        name="email"
        :valid="!$v.enteredEmail.$error"
        :error-message="$t('Please provide valid e-mail address.')"
      />
      <SfButton
        class="subscribe__button"
        @click="handleSubscription"
        :disabled="showSpinner || showVerification"
      >
        <span
          v-if="!showSpinner && !showVerification"
        >
          {{ $t('ok') }}
        </span>
        <ASpinner
          v-if="showSpinner && !showVerification"
          :size="20.75"
          class="spinner"
        />
        <SfIcon
          v-if="!showSpinner && showVerification"
          icon="check"
          size="2rem"
          color="white"
        />
      </SfButton>
    </div>
  </section>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import config from 'config';

import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfInput from '@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue';
import ASpinner from '~/theme/components/atoms/a-spinner';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue'
import i18n from '@vue-storefront/i18n';

export default {
  name: 'ASubscribe',
  components: {
    SfButton,
    SfInput,
    ASpinner,
    SfIcon
  },
  computed: {
    soldOut () {
      return (this.product.estimated_receipt_date && this.product.name)
        ? i18n.t("Unfortunately sold out. We estimate {name} is back in stock on {date}. Do you want to receive an email when it's back in stock?",
          { name: this.product.name || '', date: this.product.estimated_receipt_date || '' })
        : i18n.t("Out of stock. Receive an email when it's back!")
    }
  },
  data () {
    return {
      enteredEmail: '',
      personalDetails: this.$store.state.checkout.personalDetails,
      showSpinner: false,
      showVerification: false
    }
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  validations: {
    enteredEmail: {
      required,
      minLength: minLength(3),
      email
    }
  },
  methods: {
    ...mapActions('checkout', {
      savePersonalDetails: 'savePersonalDetails'
    }),
    handleSubscription () {
      this.$v.$touch();
      if (this.$v.$invalid) return
      this.showSpinner = true;
      this.personalDetails.emailAddress = this.enteredEmail
      this.savePersonalDetails(this.personalDetails)
      setTimeout(() => {
        this.showSpinner = false;
        this.showVerification = true
        this.emitSubscribeEvent()
      }, 1000)
    },
    emitSubscribeEvent () {
      this.$store.dispatch('klaviyo/subscribeToBackInStock', { email: this.enteredEmail, product: this.product })
      this.$bus.$emit('subscribe-back-in-stock', this.enteredEmail, this.product.sku, this.product.id)
    }
  },
  mounted () {
    this.showVerification = false
    const personalDetails = JSON.parse(localStorage.getItem('shop/checkout/personal-details'))
    if (!personalDetails) {
      this.enteredEmail = ''
      return
    }
    this.enteredEmail = personalDetails.emailAddress
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.subscribe{
  &__message {
    margin-top: var(--spacer-xl);
    text-align: center;
  }
  &__wrapper {
    max-width: 440px;
    display: flex;
    margin: auto;
    height: 3em;
    padding: 0 var(--spacer-sm);
    @include for_mobile {
      padding: 0 var(--spacer-xs);
    }
  }
  &__input {
  --input-label-font-size: var(--font-size--base);
  width: 70%;
  margin-right: var(--spacer-xs);
  ::v-deep {
  input {
    font-size: var(--font-size--base);
    background-color: var(--c-light-variant);
    border-radius: 5px;
  }
  input:focus-visible {
    outline-width: 0;
  }
}
  }
  &__button {
    background-color: var(--c-primary);
    width: 30%;
  }
}

.spinner{
  --loader-spinner-stroke: currentColor;
}

</style>
