export default {
  data: {
    activeOption: 0
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    selectedOption: {
      default: null,
      type: String
    },
    availableOptions: {
      default: null,
      type: Array
    },
    attributeCode: {
      type: String,
      required: true
    }
  },
  methods: {
    isOptionDisabled (option) {
      if (!this.availableOptions) return false
      return !this.availableOptions.includes(option.id)
    },
    isOptionSelected (option) {
      if (this.isOptionDisabled(option)) {
        return false
      }
      return option.id === this.activeOption
    },
    handleChangeOption (optionId) {
      this.$emit('change', { type: this.attributeCode, label: optionId, id: optionId })
    },
    changeVariant (payload) {
      let flavour = payload.id?.flavour.toString()
      if (flavour !== undefined) {
        this.activeOption = flavour
        this.handleChangeOption(flavour)
      }
    }
  },
  created () {
    this.activeOption = this.selectedOption || this.options[0].id;
  },
  mounted () {
    this.$bus.$on('select-product', this.changeVariant)
  },
  watch: {
    selectedOption () {
      this.activeOption = this.selectedOption;
    },
    availableOptions (newValue) {
      if (this.activeOption === null) return
      if (!newValue.includes(this.activeOption)) {
        this.handleChangeOption(null)
      }
    }
  }
};
