<template>
  <div class="sf-gallery">
    <div class="sf-gallery__stage">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul
            class="glide__slides"
            :class="{'glide__slides-fade': isFader}"
          >
            <li
              v-for="(picture, index) in images"
              :key="'slide-' + index"
              class="glide__slide"
              :class="{'glide__slide-fade': isFader}"
              @mouseover="startZoom(picture)"
              @mousemove="moveZoom($event, index)"
              @mouseout="removeZoom(index)"
            >
              <div class="product__item swiper-slide swiper-slide-visible swiper-slide-active" v-if="picture.desktop.type === 'video'">
                <AImage
                  id="product-image"
                  :src="picture.desktop.url"
                  class="sf-gallery__big-image"
                  :alt="picture.desktop.alt"
                  placeholder="/assets/placeholder.jpg"
                  :width="imageWidth"
                  :height="imageHeight"
                />
                <video
                  id="product-video"
                  class="product-image__video"
                  :poster="picture.desktop.url"
                  autoplay="true"
                  muted
                  loop=""
                  playsinline=""
                >
                  <source :src="picture.desktop.vedio_url" type="video/mp4">
                </video>
              </div>
              <AImage
                ref="sfGalleryBigImage"
                class="sf-gallery__big-image"
                :class="{ 'sf-gallery__big-image--has-zoom': enableZoom }"
                :src="picture.desktop.url"
                :alt="picture.desktop.alt"
                placeholder="/assets/placeholder.jpg"
                :width="imageWidth"
                :height="imageHeight"
                @click="$emit('click:stage', { picture, index })"
                :key="picture.desktop.url"
                v-else
              />
            </li>
          </ul>
        </div>

        <div class="sf-gallery__controls smartphone-only" data-glide-el="controls">
          <button class="sf-button sf-button--pure btn-control btn-prev" data-glide-dir="<">
            <SfIcon icon="arrow_left" size="2rem" />
          </button>
          <button class="sf-button sf-button--pure btn-control btn-next" data-glide-dir=">">
            <SfIcon icon="arrow_right" size="2rem" />
          </button>
        </div>
      </div>
      <div
        v-if="outsideZoom && pictureSelected"
        ref="outSide"
        :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
      >
        <AImage
          ref="imgZoom"
          class="sf-gallery__zoom"
          :src="pictureSelected"
          placeholder="/assets/placeholder.jpg"
          :width="imageWidth"
          :height="imageHeight"
          :lazy="false"
        />
      </div>
    </div>
    <div class="sf-gallery__thumbs">
      <slot name="thumbs" v-bind="{ images, active: activeIndex, go }">
        <SfButton
          v-for="(image, index) in images"
          :key="'img-' + index"
          class="sf-button--pure sf-gallery__item"
          :class="{ 'sf-gallery__item--selected': index === activeIndex }"
          @click="go(index)"
        >
          <AImage
            class="sf-gallery__thumb"
            :src="image.desktop.url"
            :alt="image.desktop.alt || $t('Product')"
            placeholder="/assets/placeholder.jpg"
            :width="thumbWidth"
            :height="thumbHeight"
          />
        </SfButton>
      </slot>
    </div>
  </div>
</template>
<script>
import Glide from '@glidejs/glide';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import AImage from '~/theme/components/atoms/a-image'

export default {
  name: 'MGallery',
  components: {
    SfButton,
    SfIcon,
    AImage
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    /**
     * Images list
     */
    images: {
      type: Array,
      default: () => []
    },
    /**
     * Images width, without unit
     */
    imageWidth: {
      type: [Number, String],
      default: 422
    },
    /**
     * Images height, without unit
     */
    imageHeight: {
      type: [Number, String],
      default: 664
    },
    /**
     * Thumb width, without unit
     */
    thumbWidth: {
      type: [Number, String],
      default: 160
    },
    /**
     * Thumb height, without unit
     */
    thumbHeight: {
      type: [Number, String],
      default: 160
    },
    /**
     * Initial image number (starting from 1)
     */
    current: {
      type: Number,
      default: 1
    },
    /**
     * Glide slider options (https://glidejs.com/docs/options/)
     */
    sliderOptions: {
      type: Object,
      default () {
        return {
          type: 'slider',
          autoplay: false,
          rewind: false,
          gap: 0
        };
      }
    },
    /**
     * Image zoom inside or overlap the stage
     */
    outsideZoom: {
      type: Boolean,
      default: false
    },
    /**
     * Toogle for image zoom or overlap the stage
     */
    enableZoom: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      positionStatic: {},
      eventHover: {},
      pictureSelected: '',
      glide: null,
      activeIndex: this.current - 1,
      style: '',
      isFader: false
    };
  },
  computed: {
    mapPictures () {
      // map images to handle picture tags with SfImage
      return this.images.map(({ desktop, big }) => ({
        mobile: desktop,
        desktop: big
      }));
    },
    updatedSliderOptions () {
      return { ...this.sliderOptions, startAt: this.activeIndex };
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.mountSlider()
    });
    this.onVideoLoaded()
  },
  beforeDestroy () {
    if (this.glide) {
      this.glide.destroy();
    }
  },
  methods: {
    onVideoLoaded () {
      var video = document.getElementById('product-video');
      var image = document.getElementById('product-image');
      if (image) {
        setTimeout(() => {
          image.style.display = 'none';
          video.style.display = 'block';
        }, 2000);
      }
    },
    mountSlider () {
      // handle slider with swipe and transitions with Glide.js
      // https://glidejs.com/docs/
      if (this.images.length < 1) return;
      const glide = new Glide(this.$refs.glide, this.updatedSliderOptions);
      glide.on('run', () => {
        this.go(glide.index);
      });
      glide.mount();
      this.glide = glide;
    },
    positionObject (index) {
      if (this.$refs.sfGalleryBigImage) {
        if (this.outsideZoom) {
          return this.$refs.glide.getBoundingClientRect();
        } else {
          return this.$refs.sfGalleryBigImage[index].$el.getBoundingClientRect();
        }
      }
      return '';
    },
    go (index) {
      if (!this.glide) return;
      this.$bus.$emit('select-product', this.images[index]);
      this.activeIndex = index;
      /**
       * Event for current image change (`v-model`)
       * @type {Event}
       */
      this.$emit('click', index + 1);
      if (this.glide) {
        this.glide.go(`=${index}`);
      }
    },
    startZoom (picture) {
      if (this.enableZoom) {
        const { zoom, big, desktop } = picture;
        this.pictureSelected = (zoom || big || desktop).url;
      }
    },
    moveZoom ($event, index) {
      if (this.enableZoom) {
        this.eventHover = $event;
        if (this.outsideZoom) {
          this.positionStatic = this.positionObject(index);
          this.$refs.imgZoom.$el.children[0].style.cssText =
            'top: 0; transform: scale(2);';
          this.$refs.imgZoom.$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        } else {
          this.positionStatic = this.positionObject(index);
          this.$refs.sfGalleryBigImage[index].$el.children[0].style.cssText =
            'top: 0; transform: scale(2);';
          this.$refs.sfGalleryBigImage[index].$el.children[0].style.transformOrigin = `${
            $event.clientX - this.positionStatic.x
          }px ${$event.clientY - this.positionStatic.y}px`;
        }
      }
    },
    removeZoom (index) {
      if (this.enableZoom) {
        this.pictureSelected = '';
        if (this.outsideZoom) return;
        this.$refs.sfGalleryBigImage[index].$el.children[0].style.transform =
          'scale(1)';
      }
    },
    moveFader (index) {
      this.isFader = true
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeIndex = index - 1
          this.glide.update({ startAt: index - 1 })
        }, 150);
        setTimeout(() => {
          this.isFader = false
        }, 300);
      })
    }
  },
  watch: {
    current (value) {
      this.moveFader(value)
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfGallery.scss";

.glide__slide .product__item video, .glide__slide .sf-gallery__big-image{
  width: 100%;
  img {
    max-width: 100%;
    border-radius: 16px;
    overflow: hidden;
    width: auto;
    height: auto;
  }
}
.glide__slides.glide__slides-fade {
  animation-name: example;
  animation-duration: 150ms;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

@keyframes example {
  from { opacity: 1; }
  to { opacity: 0; }
}

.sf-gallery {
  &__controls {

    .btn-control {
      position: absolute;
      top: calc(50% - 2rem);
      z-index: 5;

      &.btn-prev {
        left: 0.25rem;
      }

      &.btn-next {
        right: 0.25rem;
      }
    }
  }
}
#product-video {
  display: none;
}
</style>
