<template>
  <div class="m-product-gallery">
    <div v-if="productHasBadges" class="badges__wrapper">
      <span
        v-for="badge in badgesToDisplay"
        :key="badge.label"
        class="badges__badge"
        :style="{color: badge.textColor, 'background-color': badge.bgColor}"
      >
        {{ badge.label }}
      </span>
    </div>
    <SfImage
      v-if="!isOnline && offlineImage"
      :src="offlineImage"
    />
    <MGallery
      ref="gallery"
      :images="productImages"
      :image-width="imageSize"
      :image-height="imageSize"
      :slider-options="glideJsOptions"
      :current="currentIndex"
      :product="product"
    >
      <template #thumbs="{ images, active, go }">
        <div class="m-product-gallery__bullets-wrapper smartphone-only" data-glide-el="controls[nav]">
          <div
            v-if="images.length > 1"
            class="m-product-gallery__bullet"
            :key="index"
            :class="{'m-product-gallery__bullet--active': index === active}"
            @click="go(index)"
            v-for="(image, index) in images"
          />
        </div>
        <div class="m-product-gallery-thumbs-wrapper desktop-only" data-glide-el="controls[nav]">
          <SfButton
            v-for="(image, index) in images"
            :key="'img-' + index"
            class="sf-button--pure sf-gallery__item"
            :class="{ 'sf-gallery__item--selected': index === active }"
            @click="go(index)"
          >
            <AImage
              class="sf-gallery__thumb"
              :src="image.desktop.url"
              :alt="image.desktop.alt || $t('Product')"
              placeholder="/assets/placeholder.jpg"
              :width="116"
              :height="116"
            />
            <span class="sr-only">Product thumb</span>
          </SfButton>
        </div>
        <div class="swiper-bottom smartphone-only" v-if="product.type_id === 'simple'">
          <Swiper
            class="swiper gallery-top"
            :current="currentIndex"
            :options="swiperOptionTop"
            ref="swiperTop"
          >
            <SwiperSlide
              v-for="(image, index) in images"
              :key="'img-' + index"
              class="sf-button--pure"
              :class="{'active': index === active}"
              :aria-label="'Image ' + index"
              @click="go(index)"
            >
              <SfImage
                class="sf-gallery__thumb"
                :src="image.mobile.url"
                :alt="image.alt"
                :placeholder="image.placeholder"
                :width="thumbWidth"
                :height="thumbHeight"
                :image-tag="thumbImageTag"
                :nuxt-img-config="thumbNuxtImgConfig"
                @click="go(index)"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </template>
    </MGallery>
    <MParentGallery
      :images="parentImages"
      v-if="parentImages.length > 0"
      :products="products"
      :product="product"
      :configuration="configuration"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import config from 'config';
import { Logger } from '@vue-storefront/core/lib/logger';
import MGallery from '../molecules/m-gallery.vue'
import MParentGallery from '../molecules/m-parent-gallery.vue'
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue'
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import { onlineHelper, isServer } from '@vue-storefront/core/helpers';
import { formatPrice, getBadgesToDisplay, countPercentage } from '~/theme/helpers';
import AImage from '~/theme/components/atoms/a-image'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import SwiperCore, { Navigation } from 'swiper';
SwiperCore.use([Navigation]);

export default {
  name: 'MProductGallery',
  components: {
    MGallery,
    SfImage,
    SfButton,
    AImage,
    MParentGallery,
    Swiper,
    SwiperSlide
  },
  directives: {
    swiper: directive
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    selectedChildProduct: {
      type: Object,
      default: () => ({})
    },
    gallery: {
      type: Array,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    },
    offlineImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    thumbnails: {
      type: Boolean,
      default: true
    },
    priceOnButton: {
      type: Number,
      default: null
    },
    strikeThroughPrice: {
      type: Number,
      default: null
    },
    products: {
      type: Array,
      required: true,
      default: () => []
    },
    isOutOfStock: {
      type: Boolean,
      default: false
    },
    sliderId: {
      type: String,
      default: 'simple-gallery-slider'
    },
    thumbImageTag: {
      type: String,
      default: 'img'
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({})
    },
    thumbWidth: {
      type: [Number, String],
      default: null
    },
    thumbHeight: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    let current = this
    return {
      forcedIndex: null,
      badges: null,
      swiperOptionTop: {
        spaceBetween: 10,
        slideToClickedSlide: true,
        breakpoints: {
          0: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          767: {
            slidesPerView: 5,
            spaceBetween: 10
          },
          1023: {
            slidesPerView: 6,
            spaceBetween: 10
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/getAttributeListByCode'
    }),
    isOnline () {
      return onlineHelper.isOnline;
    },
    imageSize () {
      if (isServer) return 320
      return screen.availWidth <= 1024 ? 320 : config?.products?.gallery?.width || 512
    },
    glideJsOptions () {
      return {
        type: 'slider',
        autoplay: false,
        rewind: true,
        gap: '2px'
      }
    },
    productHasBadges  () {
      if (this.product.type_id === 'configurable') {
        return !!this.selectedChildProduct.badges
      }
      return !!this.product.badges
    },
    selectedProduct () {
      if (this.product.type_id === 'configurable') {
        return this.selectedChildProduct
      }
      return this.product
    },
    badgesToDisplay () {
      const badges = getBadgesToDisplay(this.selectedProduct.badges, this.attributesByCode.badges.options, this.selectedProduct.badges_sort_order, this.getLabelOfSpecialBadge.bind(this))
      return this.strikeThroughPrice === this.priceOnButton
        ? badges.filter(badge => !/^(discount_amount|discount_percent)/.test(badge.rawLabel))
        : badges
    },
    productImages () {
      if (this.product.type_id === 'configurable') {
        return this.gallery.filter(item => item.id !== undefined);
      }
      return this.gallery
    },
    parentImages () {
      if (this.product.type_id === 'configurable') {
        return this.gallery.filter(item => item.id === undefined);
      }
      return []
    },
    currentIndex () {
      const index = this.productImages.findIndex(imageObject => {
        if (!imageObject?.id) return null
        return Object.entries(imageObject.id).every(([attributeCode, attributeValue]) => {
          if (!this.configuration[attributeCode]?.id) {
            Logger.error(`Cannot find attributeCode '${attributeCode}' in configuration`, 'Gallery', { configuration: this.configuration })()
            return -1
          }
          return +this.configuration[attributeCode].id === attributeValue
        })
      })
      return index === -1 ? 1 : index + 1;
    }
  },
  methods: {
    getLabelOfSpecialBadge (badgeRawLabel) {
      switch (badgeRawLabel) {
        case 'discount_amount':
          const discountAmount = formatPrice(this.strikeThroughPrice - this.priceOnButton)
          return this.priceOnButton ? this.$t('Save {discount}', { discount: discountAmount }) : this.$t('Sold Out')
        case 'discount_percent':
          const discountPercent = countPercentage(this.strikeThroughPrice, this.priceOnButton)
          return this.priceOnButton ? this.$t('Save {discount}', { discount: discountPercent }) + '%' : this.$t('Sold Out')
        default:
          return badgeRawLabel
      }
    }
  },
  watch: {
    currentIndex () {
      this.forcedIndex = null
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.badges {
  &__wrapper {
    box-sizing: border-box;
    padding: var(--spacer-sm);
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: absolute;
    @include for-mobile {
      top: 0;
    }
    @include for-desktop {
      margin-left: calc(var(--gallery-thumb-width));
    }
  }
  &__badge {
    color: var(--c-white);
    background-color: var(--c-primary);
    white-space: nowrap;
    margin-right: var(--spacer-sm);
    margin-bottom: var(--spacer-sm);
    font-weight: var(--font-weight--semibold);
    font-size: var(--font-size--xs);
    padding: var(--spacer-xs) var(--spacer-sm);
    border-radius: var(--spacer-base);
    @include for-mobile {
      margin-right: var(--spacer-xs);
      margin-bottom: var(--spacer-xs);;
    }
  }
}
.m-product-gallery {
  position: relative;
  flex: 0.5;
  @include for-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @include for-desktop {
    margin-top: 0.625rem;
  }
  &__bullets-wrapper {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: none;
  }
  &__bullet {
    width: var(--product-gallery-bullet-size);
    height: var(--product-gallery-bullet-size);
    border-radius: 100%;
    background-color: var(--product-gallery-bullets-color);
    margin: 0.3rem;
    cursor: pointer;
    &--active {
      width: var(--product-gallery-active-bullet-size);
      height: var(--product-gallery-active-bullet-size);
      background-color: var(--product-gallery-active-bullet-color);
    }
  }
  ::v-deep {
    .sf-image img {
      width: var(--image-width, auto);
      border-radius: 8px;
    }
    .sf-image.has-size {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sf-image.has-size > img {
      position: relative;
      transform: none;
      -webkit-transform: none;
      top: 0;
      @include for-mobile {
        min-width: var(--product-gallery-mobile-width);
        width: 100%;
        max-width: 100%;
      }
    }
    .glide__slide {
      align-items: center;
      justify-content: center;
      min-width: var(--product-gallery-width);
      width: var(--product-gallery-width);
      max-width: var(--product-gallery-width);
      @include for-mobile {
        min-width: var(--product-gallery-mobile-width);
        max-width: 100%;
        width: 100%;;
        img {
          max-width: 100%;
          width: 100%;;
        }
      }
    }
    .sf-gallery__stage {
      max-width: var(--product-gallery-width);
      @include for-mobile {
        max-width: 100%;
        width: 100%;;
      }
    }
    .sf-gallery {
      max-width: var(--product-gallery-width);
      min-height: 500px;
      @include for-mobile {
        --gallery-thumbs-flex-direction: column;
        min-height: 200px;
        max-width: 100%;
        min-width: var(--product-gallery-mobile-width);
        width: 100%;
      }
      @include for-desktop {
        --gallery-thumb-height: var(--gallery-thumb-width);
        max-width: var(--product-gallery-width + --gallery-thumb-width);
      }
      &__thumbs {
        width: 100%;
        @include for-desktop {
          max-height: var(--product-gallery-bullets-track-height);
        }
        margin-top: 15px;
        img {
          border-radius: 8px;
        }
        @include for-desktop {
          max-height: var(--product-gallery-height);
        }
      }
    }
    .swiper-bottom {
      padding: 0 var(--spacer-15);
      .active {
        opacity: 0.5;
      }
      .sf-image {
          width: auto;
          height: auto;
          img {
              width: 100%;
              height: auto;
            }
        }
    }
    .m-product-gallery-thumbs-wrapper {
      cursor: grab;
      @include for-desktop {
        width: var(--gallery-thumb-width);
      }
      @include for-mobile {
        display: none;
      }
    }
  }
}
</style>
