<template>
  <div class="sfgallery">
    <div class="sf-gallery-popup">
      <span class="close-bg" @click="closeGalleryPopup" />
      <span class="gallery-close-icon" @click="closeGalleryPopup">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M6 18L18 6M6 6L18 18"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <div class="gallery-popup-img">
        <div class="gallery-popup-swiper">
          <div class="swiper-button-prev">
            <svg
              width="13"
              height="24"
              viewBox="0 0 13 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7_2)">
                <mask
                  id="mask0_7_2"
                  style="mask-type:luminance"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="13"
                  height="24"
                >
                  <path d="M-1.04907e-06 24L0 0L13 5.68248e-07L13 24L-1.04907e-06 24Z" fill="white" />
                </mask>
                <g mask="url(#mask0_7_2)">
                  <path d="M12.1329 23.8672L0.260742 12.0004L12.1329 0.128296" stroke="#fff" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_7_2">
                  <rect
                    width="24"
                    height="13"
                    fill="white"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <Swiper
            :options="swiperOptionPopup"
            ref="swiperPopup"
          >
            <SwiperSlide
              v-for="(image, index) in images"
              :key="'img-' + index"
              :aria-label="'Image ' + index"
            >
              <div>
                <SfImage
                  class="sf-gallery__thumb"
                  :src="image.mobile.url"
                  :alt="image.alt"
                  :placeholder="image.placeholder"
                  :width="thumbWidth"
                  :height="thumbHeight"
                  :image-tag="thumbImageTag"
                  :nuxt-img-config="thumbNuxtImgConfig"
                />
              </div>
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination" />
          </Swiper>
          <div class="swiper-button-next">
            <svg
              width="13"
              height="24"
              viewBox="0 0 13 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_7_2)">
                <mask
                  id="mask0_7_2"
                  style="mask-type:luminance"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="13"
                  height="24"
                >
                  <path d="M13 0L13 24L0 24L1.04907e-06 -5.68248e-07L13 0Z" fill="white" />
                </mask>
                <g mask="url(#mask0_7_2)">
                  <path d="M0.867101 0.132799L12.7393 11.9996L0.8671 23.8717" stroke="#fff" />
                </g>
              </g>
              <defs>
                <clipPath id="clip0_7_2">
                  <rect
                    width="24"
                    height="13"
                    fill="white"
                    transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 13 24)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div class="gallery-popup-right">
          <h1 class="product__title">
            {{ product.name | htmlDecode }}
          </h1>
          <m-product-options-configurable
            v-if="product.type_id =='configurable'"
            :product="product"
            :configuration="configuration"
          />
          <MProductAddToCart :products="products" class="product__add-to-cart o-product-details__add-to-cart--mobile-bottom" />
        </div>
      </div>
    </div>
    <div class="swiper-desktop flex">
      <div class="thumb-slider-bottom">
        <div class="gallery-box">
          <Swiper
            class="swiper gallery-top"
            :options="swiperOptionTop"
            ref="swiperTop"
          >
            <SwiperSlide
              v-for="(image, index) in images"
              :key="'img-' + index"
              class="sf-button--pure"
              :aria-label="'Image ' + index"
            >
              <SfImage
                class="sf-gallery__thumb"
                :src="image.mobile.url"
                :alt="image.alt"
                :placeholder="image.placeholder"
                :width="thumbWidth"
                :height="thumbHeight"
                :image-tag="thumbImageTag"
                :nuxt-img-config="thumbNuxtImgConfig"
                @click="openGalleryPopup(index)"
              />
            </SwiperSlide>
            <div class="swiper-pagination" slot="pagination" />
          </Swiper>
          <div class="swiper-button-next">
            <i class="material-icons" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MProductOptionsConfigurable from '~/theme/components/molecules/m-product-options-configurable';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
import SwiperCore, { Navigation } from 'swiper';
const MProductAddToCart = () => import(/* webpackChunkName: "m-product-add-to-cart" */'~/theme/components/molecules/m-product-add-to-cart');
SwiperCore.use([Navigation]);

export default {
  name: 'MParentGallery',
  components: {
    SfImage,
    Swiper,
    SwiperSlide,
    MProductAddToCart,
    MProductOptionsConfigurable
  },
  directives: {
    swiper: directive
  },
  props: {
    sliderId: {
      type: String,
      default: 'gallery-slider'
    },
    images: {
      type: Array,
      default: () => []
    },
    current: {
      type: Number,
      default: 1
    },
    thumbImageTag: {
      type: String,
      default: 'img'
    },
    thumbNuxtImgConfig: {
      type: Object,
      default: () => ({})
    },
    thumbWidth: {
      type: [Number, String],
      default: null
    },
    thumbHeight: {
      type: [Number, String],
      default: null
    },
    products: {
      type: Array,
      required: true,
      default: () => []
    },
    product: {
      type: Object,
      default: () => ({})
    },
    configuration: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      currentImage: {},
      swiperOptionTop: {
        spaceBetween: 10,
        slideToClickedSlide: true,
        breakpoints: {
          0: {
            slidesPerView: 4,
            spaceBetween: 10
          },
          767: {
            slidesPerView: 5,
            spaceBetween: 10
          },
          1023: {
            slidesPerView: 6,
            spaceBetween: 10
          }
        }
      },
      swiperOptionPopup: {
        spaceBetween: 10,
        slidesPerView: 1,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.gallery-popup-swiper .swiper-button-next',
          prevEl: '.gallery-popup-swiper .swiper-button-prev'
        }
      }
    };
  },
  computed: {
    swiperTop () {
      return this.$refs.swiperTop.$swiper;
    },
    swiperPopup () {
      return this.$refs.swiperPopup.$swiper;
    }
  },
  methods: {
    openGalleryPopup (index) {
      document.body.classList.add('gallery-popup-open');
      this.swiperPopup.slideTo(index, 500, false)
    },
    closeGalleryPopup () {
      document.body.classList.remove('gallery-popup-open');
    }
  }
};
</script>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.sfgallery {
  width: 100%;
  box-sizing:border-box;
}
.product {
  .sf-gallery-popup {
    .close-bg {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--spacer-40);
    overflow: auto;
    padding: var(--spacer-20);
    z-index: 99999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.3s;
    width: 100%;
    box-sizing:border-box;
    @include for-mobile {
      padding:0.5rem;
    }
    .gallery-close-icon {
      position: absolute;
      padding: 0.938rem;
      right: 0;
      top: 0;
      cursor: pointer;
      z-index: 9;
      svg,
      img {
        width: 2.313rem;
        height: 2.313rem;
      }
    }
    .gallery-popup-img {
      max-width: 1200px;
      width: 100%;
      position: relative;
      z-index: 1;
      .gallery-popup-right {
        @include for-desktop {
          padding-left: 25px;
        }
        h1 {
          font-weight: var(--font-weight--medium);
          letter-spacing: var(--spacer-2xs);
          margin:0 0 var(--spacer-xs) 0;
          text-align: center;
          text-transform: uppercase;
          @include for-mobile {
            font-size: var(--h2-font-size);
          }
        }
      }
      .gallery-popup-swiper {
        max-width: 50%;
        @include for-mobile {
          max-width: 100%;
          padding-bottom: var(--spacer-sm);
        }
      }
      @include for-desktop {
        display: flex;
      }
      .sf-gallery__thumb {
        width: auto;
        height: auto;
        cursor: pointer;
      }
    }
    .sf-button  {
      margin: var(--spacer-10) auto auto auto;
      border-radius: var(--spacer-50);
    }
  }
  .thumb-slider-bottom {
    max-width: 710px;
    padding-top: var(--spacer-10);
    margin: auto;
    @include for-mobile {
      padding: var(--spacer-10) 0 0 0;
      overflow: hidden;
      width: 100%;
      max-width: 100%;
    }
    .sf-gallery__thumb {
      width: auto;
      height: auto;
      cursor: pointer;
    }
  }
}
.gallery-popup-open {
  overflow: hidden;
  .sf-gallery-popup {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    .swiper-desktop {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      @include for-mobile {
        display: block;
      }
    }
  }
  .swiper-desktop {
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing:border-box;
  }
}
.gallery-popup-swiper {
  position: relative;
  .swiper-container {
      overflow: hidden;
  }
  .swiper-slide {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-wrapper img {
    max-height: calc(100vh - 2.5rem);
    @include for-mobile {
      width: 100%;
      max-width: 100%;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.4);
    border-radius: var(--spacer-25);
    display: flex;
    justify-content: center;
    align-items:center;
    width: var(--spacer-40);
    height: var(--spacer-40);
    right: var(--spacer-30);
    z-index: 9999;
    margin-top: -20px;
    @include for-mobile {
      right: var(--spacer-15);
    }
    cursor: pointer;
    svg,
    img {
      width: var(--spacer-10);
      height: auto;
    }
  }
  .swiper-button-prev {
    left: var(--spacer-30);
    right: inherit;
    @include for-mobile {
      left: var(--spacer-15);
    }
  }
}
</style>
