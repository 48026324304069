<template>
  <option :value="value" class="sf-select__option" tabindex="0">
    <!-- @slot -->
    <slot />
  </option>
</template>
<script>
export default {
  name: 'ASelectOption',
  props: {
    value: {
      type: [String, Number, Object],
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
  .sf-select__option {
    width: 500px;
    border-style: none;
    -webkit-appearance: none;
  }
</style>
