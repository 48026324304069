<template>
  <div
    class="sf-select"
    :class="{
      'is-selected': value || placeholder,
      'is-required': required,
      'is-disabled': disabled,
      'is-invalid': !valid,
    }"
  >
    <label :for="label" class="sf-select__label">
      <slot name="label" :label="label">
        {{ label }}
      </slot>
    </label>
    <div class="select-wrapper">
      <select
        :id="label"
        v-focus
        :value="value"
        class="sf-select__dropdown product__select-size"
        @change="changeHandler"
      >
        <!-- empty option by default, may be used as placeholder -->
        <option
          v-if="placeholder"
          class="sf-select__placeholder sf-select__option"
          disabled
          :selected="!!placeholder"
          value
        >
          <slot name="placeholder" v-bind="{ placeholder }" />
          {{ placeholder }}
        </option>
        <slot />
      </select>
    </div>
    <div class="sf-select__error-message" v-show="!valid">
      <transition name="sf-fade">
        <!-- @slot Custom error message of form select -->
        <slot v-if="!valid" name="errorMessage" v-bind="{ errorMessage }">
          <span> {{ errorMessage }} </span>
        </slot>
      </transition>
    </div>
  </div>
</template>
<script>
import { focus } from '@storefront-ui/vue/src/utilities/directives';

export default {
  name: 'ASelect',
  directives: { focus },
  props: {
    /**
       * Select field label
       */
    label: {
      type: String,
      default: ''
    },
    /**
       * Required attribute
       */
    required: {
      type: Boolean,
      default: false
    },
    /**
       * Validate value of form select
       */
    valid: {
      type: Boolean,
      default: true
    },
    /**
       * Disabled status of form select
       */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
       * Error message value of form select. It will be appeared if `valid` is `true`.
       */
    errorMessage: {
      type: String,
      default: 'This field is not correct.'
    },
    /**
       * Value selected.
       */
    value: {
      type: String,
      default: ''
    },
    /**
       * Adds placeholder
       */
    placeholder: {
      type: String,
      default: ''
    }
  },
  methods: {
    changeHandler (event) {
      this.$emit('input', event.target.value);
    }
  }
};
</script>
<style lang="scss">
  @import "~@storefront-ui/shared/styles/components/molecules/SfSelect.scss";
  .chevron {
    border-bottom-color: var(--c-primary);
    border-bottom-width: 1px;
    height: 100%;
    border-bottom-style: solid;
    align-self: flex-end;
    margin-bottom: var(--spacer-2xs);
    padding-bottom: var(--spacer-2xs);
  }
  .select-wrapper {
    display: flex;
    box-shadow: none;
  }
  .sf-select__dropdown.product__select-size {
    padding: 0 10px;
    border-radius: 2px;
    -webkit-appearance: none;
    border-color: var(--c-primary);
    background: var(--c-wishlist-and-dropdown) url(/assets/icons/chevron-down.svg) no-repeat right 10px center;
    height: 2rem;
    font-family: var(--font-family--primary);
    .sf-select__option {
      font-family: var(--font-family--primary);
    }
  }

  option:hover {
    background-color: #a3a5ad;
  }
</style>
