import config from 'config';

export default {
  data () {
    return {
      rateFilterValue: null,
      reviewsSku: null
    }
  },
  computed: {
    reviewsCount () {
      return this.reviews.length
    },
    reviewsSummary () {
      const summaryMock = {
        count: 0,
        rating: 0,
        distribution: [ 0, 0, 0, 0, 0 ]
      }
      return this.$store.state['stamped-io'].summary[this.reviewsSku] || summaryMock
    },
    totalReviews () {
      const productReviews = this.$store.state['stamped-io'].reviews[this.reviewsSku]
      return productReviews?.length > 0 ? productReviews : []
    },
    reviews () {
      const productReviews = this.$store.state['stamped-io'].reviews[this.reviewsSku]
      if (!productReviews?.length > 0) return []
      if (!this.rateFilterValue) return productReviews
      return productReviews.filter(_ => _.rating === this.rateFilterValue)
    },
    totalReviewsPages () {
      if (this.rateFilterValue) {
        return Math.round(this.reviewsSummary.distribution[this.rateFilterValue - 1] / config.stampedIo.reviewsPerPage)
      }
      return this.$store.state['stamped-io'].totalPages[this.reviewsSku] || 0
    }
  },
  methods: {
    filterReviewsByRating (rate) {
      if (this.rateFilterValue === rate) {
        this.rateFilterValue = null
        return
      }
      this.rateFilterValue = rate
    }
  }
}
