<template>
  <div>
    <div class="tier-title" v-if="product.tier_prices.length !== 0 && options.length !== 0">
      <h3>{{ $t('Bundle & Save') }}</h3>
    </div>
    <AButtons
      class="a-tier-prices"
      :key="product.tier_prices.qty"
      v-if="product.tier_prices.length !== 0 && options.length !== 0"
      @change="change"
      :options="options"
      :disabled="disabled"
    >
      <template v-slot="{ option }">
        <div class="a-tier-prices__quantity">
          {{ option.qty == 1 ? $t('Single') : option.qty == 2 ? $t('Duo') : option.qty == 3 ? $t('Trio') : option.label }}
          <div class="a-tier-prices__discount">
            {{ option.discount > 0 ? $t('Save {discount}', { discount: option.discount }) +'%' : $t('Standard Price') }}
          </div>
          <div class="a-tier-prices__price">
            {{ formatPrice(option.price) }}
          </div>
          <div class="a-tier-prices__regular_price" v-if="option.price < option.regularPrice">
            {{ formatPrice(option.regularPrice) }}
          </div>
        </div>
      </template>
    </AButtons>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import AButtons from '~/theme/components/atoms/a-buttons'
import { formatPrice, countPercentage } from '~/theme/helpers'

export default {
  name: 'ATiers',
  components: { AButtons },
  props: {
    product: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    productQuantity: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.$emit('change', 1);
  },
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/getAttributeListByCode'
    }),
    tierPrices () {
      let tierBoxQty1 = {
        qty: 1,
        price: this.product.final_price || this.product.regular_price,
        value: this.product.final_price || this.product.regular_price,
        label: `${this.product.tier_value} ${this.tierUnitsOfMeasurment || ''}`,
        regularPrice: this.product.regular_price
      }
      tierBoxQty1.discount = countPercentage(tierBoxQty1.regularPrice, tierBoxQty1.price);
      const sortedTiers = [...this.product.tier_prices].sort((tierA, tierB) => tierA.qty - tierB.qty)
      return this.product.tier_show_tier_box_qty_1 ? [ tierBoxQty1, ...sortedTiers ] : sortedTiers
    },
    tierUnitsOfMeasurment () {
      return this.attributesByCode.tier_uom?.options?.find(({ value }) => +value === +this.product.tier_uom)?.label
    },
    options () {
      if (!this.product.tier_value) {
        Logger.error(`Product ${this.product.name} has no tier_value indexed`)()
        return []
      }
      const availableTiers = this.tierPrices.filter(tier => tier.qty <= this.productQuantity)
      return availableTiers.map(tier => {
        let tierBoxQty = {
          label: `${tier.qty * this.product.tier_value} ${this.tierUnitsOfMeasurment || ''}`,
          qty: tier.qty,
          price: this.isPricePerUnit ? tier.value < this.product.final_price && tier.qty > 1 ? tier.value : this.product.final_price : tier.value * tier.qty,
          regularPrice: this.isPricePerUnit ? this.product.regular_price : this.product.regular_price * tier.qty,
          value: tier.qty
        }

        if (this.isPricePerUnit) {
          tierBoxQty.price *= tier.qty;
          tierBoxQty.regularPrice *= tier.qty;
        }

        tierBoxQty.discount = countPercentage(tierBoxQty.regularPrice, tierBoxQty.price);
        return tierBoxQty
      })
    },
    isPricePerUnit () {
      const TIER_PRICE_PER_UNIT_CODE = 120 // total price is 119
      return this.product.tier_display_price === null || this.product.tier_display_price === TIER_PRICE_PER_UNIT_CODE
    }
  },
  methods: {
    change (option) {
      if (option === null) {
        this.product.qty = 1
        return this.$emit('change', 1);
      }
      this.product.qty = option.qty
      this.$emit('change', option.qty);
    },
    formatPrice (value) {
      return formatPrice(value)
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";
  .tier-title {
    position: relative;
    text-align: center;
    z-index: 0;
    margin-top: 15px;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      top: 0;
      bottom: 0;
      background-color: var(--c-true-black);
      margin: auto;
      z-index: -1;
    }
    h3 {
      color: var(--c-true-black);
      text-transform: uppercase;
      font-size: var(--font-size--m);
      font-weight: var(--font-weight--semibold);
      display: inline-block;
      background-color: var(--c-white);
      vertical-align: top;
      padding: 0 8px;
      margin: 0;
    }
  }
  .a-tier-prices {
    --tier-prices-border-color: var(--c-gray-variant);
    --tier-prices-quantity-color: #464646;
    --tier-prices-price-color: #ff1616;
    --tier-prices-font-size: calc(var(--font-size--sm) - 0.2em);
    --tier-prices-button-size: auto;
    --tier-prices-button-padding: var(--spacer-15);
    --button-font-weight: normal;

    justify-content: center;
    @include for-desktop {
      margin-top: var(--spacer-sm);
    }

    ::v-deep {
      .button {
        height: var(--tier-prices-button-size);
        padding: var(--spacer-45) var(--tier-prices-button-padding) 0 var(--tier-prices-button-padding);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-color: var(--tier-prices-border-color);
        border-radius: 0;
        border: 1px solid rgba(0,0,0,0.2);
        text-transform: none;
        color: var(--c-true-black);
        position: relative;
        background-color: transparent;
        width: auto;
        flex: 1;
        margin: 0;
        max-width: none;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-color: var(--c-theme-color);
          opacity: 0.5;
          box-shadow: none;
        }
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 14px;
          margin: auto;
          outline: 2px solid rgba(0,0,0,0.4);
          background-color: var(--c-white);
          border: 2px solid var(--c-white);
          border-radius: 20px;
          width: 11px;
          height: 11px;
        }
      }
      .button--active {
        border: 2px dashed var(--c-true-black);
        &::after {
          background-color: var(--c-true-black);
          outline: 2px solid var(--c-true-black);
        }
      }
    }
    &__discount {
      font-size: var(--font-size--xs);
      color: var(--c-primary-variant);
      margin: 5px 0;
    }
    &__quantity {
      white-space: normal;
      font-size: var(--font-size--m);
      font-weight: var(--font-weight--normal);
      margin-bottom: var(--spacer-xs);
      color: var(--c-true-black);
    }
    &__price {
      color: var(--c-true-black);
    }
    &__regular_price {
      font-size: var(--font-size--xs);
      text-decoration: line-through;
      color: var(--c-primary-variant);
    }
  }
</style>

<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.product-configurable {
  .buttons-wrapper {
    flex-wrap: nowrap;
    gap: 10px;
    padding: 0 var(--spacer-2xs);
    @include for-mobile {
      padding: var(--spacer-xs) 0;
    }
  }
}
</style>
