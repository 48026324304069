<template>
  <div
    class="o-product-details-wrapper"
    itemprop="offers"
    itemscope
    itemtype="http://schema.org/Offer"
  >
    <meta itemprop="priceCurrency" :content="$store.state.storeView.i18n.currencyCode">
    <meta itemprop="price" :content="parseFloat(product.price_incl_tax).toFixed(2)">
    <meta itemprop="name" :content="product.name">
    <meta itemprop="availability" :content="availability">
    <meta itemprop="url" :content="product.url_path">
    <meta itemprop="name" :content="product.name">
    <div class="o-product-details product">
      <MProductGallery
        class="product__gallery"
        :offline-image="offlineImage"
        :gallery="[...movingBottle, ...gallery]"
        :configuration="productConfiguration"
        :key="gallery[0].desktop.url + product.name"
        :product="product"
        :price-on-button="priceOnButton"
        :strike-through-price="strikeThroughPrice"
        :selected-child-product="selectedChildProduct"
        :products="candidates"
      />
      <div class="product__info">
        <MProductShortInfo
          :product="product"
          :custom-options="productCustomOptions"
          :reviews="reviews"
          :score-summary="reviewsSummary"
          class="product__short-info"
        />
        <AProductRating
          :reviews="reviews"
          :show-reviews-count="true"
          :score="reviewsSummary.rating"
          class="o-product-details__rating"
          :reviews-count="reviewsSummary.count"
        />
        <div
          class="product__description product__description--desktop desktop-only"
          v-html="product.short_description || product.description"
        />
        <MTickTacClock
          class="desktop-only"
          :class="{ 'button-busy-primary': isCartBusy }"
          :products="candidates"
          :stock="productStock"
          :key="product.sku + tier + product.qty + 'add-to-cart-up'"
          :disabled="isConfigurationInProgress"
          @change="afterProductAddToCart"
          @price-update="handleAddToCartButtonChange"
          :is-out-of-stock="isOutOfStock"
          :gifts="recommendedProductToAdd"
        />
        <div class="product-configurable">
          <MProductOptionsConfigurable
            v-if="product.type_id =='configurable'"
            :product="product"
            :configuration="productConfiguration"
            @validation="handleConfiguredProductValidation"
          />
          <MProductOptionsGroup
            v-if="product.type_id =='grouped'"
            :product-options="product.product_links"
          />
          <MProductOptionsBundle
            v-if="product.bundle_options && product.bundle_options.length > 0"
            :product="product"
          />
          <MProductOptionsCustom
            v-else-if="product.custom_options && product.custom_options.length > 0"
            :product="product"
          />
          <div
            class="product__add-to-cart-wrapper smartphone-only add-to-cart-top"
            v-if="!showBackInStockSubscribe"
          >
            <MProductAddToCart
              class="product__add-to-cart"
              :class="{ 'button-busy-primary': isCartBusy }"
              :products="candidates"
              :stock="productStock"
              :key="product.sku + tier + product.qty + 'add-to-cart-up'"
              :disabled="isConfigurationInProgress"
              @change="afterProductAddToCart"
              @price-update="handleAddToCartButtonChange"
              :is-out-of-stock="isOutOfStock"
              :gifts="recommendedProductToAdd"
            />
          </div>
          <div
            class="product__add-to-cart-wrapper smartphone-only sticky-bottom"
            v-if="!showBackInStockSubscribe"
            :class="{'active': showButton}"
          >
            <MProductAddToCart
              class="product__add-to-cart"
              :class="{ 'button-busy-primary': isCartBusy }"
              :products="candidates"
              :stock="productStock"
              :key="product.sku + tier + product.qty + 'add-to-cart-up'"
              :disabled="isConfigurationInProgress"
              @change="afterProductAddToCart"
              @price-update="handleAddToCartButtonChange"
              :is-out-of-stock="isOutOfStock"
              :gifts="recommendedProductToAdd"
            />
          </div>
          <MTickTacClock
            class="smartphone-only"
            :class="{ 'button-busy-primary': isCartBusy }"
            :products="candidates"
            :stock="productStock"
            :key="product.sku + tier + product.qty + 'add-to-cart-up'"
            :disabled="isConfigurationInProgress"
            @change="afterProductAddToCart"
            @price-update="handleAddToCartButtonChange"
            :is-out-of-stock="isOutOfStock"
            :gifts="recommendedProductToAdd"
          />
          <div
            class="product__description product__description--mobile"
            v-html="product.short_description || product.description"
          />
          <div class="set-tier" v-if="product.type_id === 'configurable'">
            <ATiers
              :product="selectedChildProduct"
              @change="setTier"
              :disabled="isConfigurationInProgress"
              :key="product.sku"
              :product-quantity="product.stock.qty"
            />
          </div>
          <div v-else>
            <ATiers
              :product="product"
              @change="setTier"
              :key="product.sku"
              :product-quantity="product.stock.qty"
            />
          </div>
          <MUpSellProducts type="upsell" />
          <div class="product-purchase">
            <ASubscribe
              v-if="showBackInStockSubscribe"
              :product="product"
            />
            <div
              class="product__add-to-cart-wrapper"
              v-if="!showBackInStockSubscribe"
            >
              <MProductAddToCart
                class="product__add-to-cart desktop-only"
                :class="{ 'button-busy-primary': isCartBusy }"
                :products="candidates"
                :stock="productStock"
                :key="product.sku + tier + product.qty + 'desktop-add-to-cart-bottom'"
                :disabled="isConfigurationInProgress"
                @change="afterProductAddToCart"
                @price-update="handleAddToCartButtonChange"
                :is-out-of-stock="isOutOfStock"
                :gifts="recommendedProductToAdd"
              />
              <div class="o-product-details__mobile-add-to-cart-bottom smartphone-only">
                <MProductAddToCart
                  class="product__add-to-cart o-product-details__add-to-cart--mobile-bottom"
                  :class="{ 'button-busy-green': isCartBusy }"
                  :products="candidates"
                  :stock="productStock"
                  :key="product.sku + tier + product.qty + 'phone-add-to-cart-bottom'"
                  :disabled="isConfigurationInProgress"
                  @change="afterProductAddToCart"
                  @price-update="handleAddToCartButtonChange"
                  :is-out-of-stock="isOutOfStock"
                  :caption="$t('Add to cart')"
                  :gifts="recommendedProductToAdd"
                />
              </div>
            </div>
            <lazy-hydrate when-visible>
              <div v-if="belowAddToCartBlock" class="product__below-add-to-cart-block-wrapper" v-html="belowAddToCartBlock" />
            </lazy-hydrate>
            <MProductPromotions
              v-model="recommendedProductToAdd"
              :products-skus="giftRulesSkus"
              :main-product-sku="+product.sku"
              :key="product.sku"
            />
            <div
              class="delivery-and-shipping"
              v-if="!isOutOfStock"
            >
              <div class="additional-pruchase-info" v-if="savedMoneyLabel">
                <AIcon
                  class="product__order-info-icon"
                  size="md"
                  icon="cash"
                  color="grey"
                /> {{ $t('Save {discount}', { discount: savedMoneyLabel }) }}
              </div>
              <div class="additional-pruchase-info">
                <AIcon
                  class="product__order-info-icon product__order-info-delivery-icon"
                  size="xs"
                  icon="delivery"
                  color="grey"
                />
                <span class="desktop-only"> {{ productLabel || $t(defaultLabel) }} </span>
                <span class="smartphone-only"> {{ productLabel || $t(defaultLabel) }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <MProductAdditionalInfo
      class="additional-info"
      :product="product"
      :attributes="productAttributes"
    />
  </div>
</template>
<script>
import config from 'config';
import { mapState, mapGetters, mapActions } from 'vuex';
import LazyHydrate from 'vue-lazy-hydration';
import { hydrateWhenIdle, hydrateWhenVisible } from 'vue-lazy-hydration'
import get from 'lodash-es/get'
import { isInStock } from '@vue-storefront/core/modules/catalog/helpers/stock'
import MProductGallery from '~/theme/components/molecules/m-product-gallery';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import MProductShortInfo from '~/theme/components/molecules/m-product-short-info';
import AIcon from '~/theme/components/atoms/a-icon';
import AProductRating from '~/theme/components/atoms/a-product-rating';
import { formatPrice } from '~/theme/helpers';
import ProductReviewsMixin from '~/theme/mixins/product-reviews.js';
import ATiers from '~/theme/components/atoms/a-tiers';
import ASubscribe from '~/theme/components/atoms/a-subscribe';
import getSelectedVariant from '@vue-storefront/core/modules/catalog/helpers/variant/getSelectedVariant'
import { isServer } from '@vue-storefront/core/helpers';

const MProductOptionsConfigurable = () => import(/* webpackChunkName: "vsf-m-product-options-configurable" */'~/theme/components/molecules/m-product-options-configurable');
const MProductOptionsBundle = () => import(/* webpackChunkName: "vsf-m-product-options-bundle" */'~/theme/components/molecules/m-product-options-bundle');
const MProductOptionsCustom = () => import(/* webpackChunkName: "vsf-m-product-options-custom" */'~/theme/components/molecules/m-product-options-custom');
const MProductOptionsGroup = () => import(/* webpackChunkName: "vsf-m-product-options-configurable" */'~/theme/components/molecules/m-product-options-group');
const MProductAdditionalInfo = () => import(/* webpackChunkName: "m-product-additional-info" */'~/theme/components/molecules/m-product-additional-info');
const MProductPromotions = () => import(/* webpackChunkName: "m-product-promotions" */'~/theme/components/molecules/m-product-promotions');
const MProductAddToCart = () => import(/* webpackChunkName: "m-product-add-to-cart" */'~/theme/components/molecules/m-product-add-to-cart');
const MTickTacClock = () => import(/* webpackChunkName: "m-tick-tac-clock" */'~/theme/components/molecules/m-tick-tac-clock');
const MUpSellProducts = () => import(/* webpackChunkName: "m-upsell-products" */'~/theme/components/molecules/m-upsell-products');

export default {
  components: {
    AProductRating,
    MProductGallery,
    MProductShortInfo,
    MProductAddToCart,
    MProductAdditionalInfo,
    MProductOptionsConfigurable: hydrateWhenIdle(MProductOptionsConfigurable),
    MProductOptionsBundle: hydrateWhenIdle(MProductOptionsBundle),
    MProductOptionsCustom: hydrateWhenIdle(MProductOptionsCustom),
    MProductOptionsGroup: hydrateWhenIdle(MProductOptionsGroup),
    MProductPromotions: hydrateWhenVisible(MProductPromotions),
    MUpSellProducts: hydrateWhenVisible(MUpSellProducts),
    ATiers,
    AIcon,
    ASubscribe,
    LazyHydrate,
    MTickTacClock
  },
  mixins: [ProductReviewsMixin],
  data () {
    return {
      recommendedProductToAdd: [],
      tier: 1,
      isConfiguredProductValid: false,
      priceOnButton: null,
      strikeThroughPrice: null,
      showButton: false
    }
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    productGallery: {
      type: Array,
      default: () => []
    },
    productConfiguration: {
      type: Object,
      default: () => ({})
    },
    productCustomOptions: {
      type: Object,
      default: () => ({})
    },
    productAttributes: {
      type: Array,
      default: () => []
    },
    productStock: {
      type: Object,
      default: () => ({})
    },
    isRenderedBySSR: {
      type: Boolean,
      required: true
    },
    isReadyToSendGTMEvents: {
      type: Boolean,
      required: true
    },
    belowAddToCartBlock: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      deliveryDate: (state) => state.ui.deliveryDate
    }),
    ...mapGetters({
      attributesByCode: 'attribute/getAttributeListByCode',
      isCartBusy: 'cart/getCartProcessingStatus'
    }),
    selectedChildProduct () {
      return getSelectedVariant(this.product, this.productConfiguration, this.product);
    },
    defaultLabel () {
      return config.deliveryLabels.defaultLabel
    },
    isOutOfStock () {
      return !isInStock(this.product)
    },
    showBackInStockSubscribe () {
      return this.isOutOfStock && config.enableBackInStockSubscribe
    },
    savedMoneyLabel () {
      const savedMoney = this.strikeThroughPrice - this.priceOnButton
      if (savedMoney === 0) return false
      return formatPrice(savedMoney)
    },
    productLabel () {
      return this.deliveryDate?.desktop?.productLabel || this.$t('Free shipping')
    },
    productLabelMobile () {
      return this.deliveryDate?.mobile?.productLabel || this.$t('Free shipping')
    },
    isConfigurationInProgress () {
      if (this.product.type_id !== 'configurable') return false
      return !this.isConfiguredProductValid
    },
    candidates () {
      return [ this.product, ...this.recommendedProductToAdd.map(product => ({ ...product, isGift: true })) ];
    },
    exampleRecommendation () {
      return [{ product: this.product }]
    },
    offlineImage () {
      const width = config.products.thumbnails.width;
      const height = config.products.thumbnails.height;
      return {
        mobile: {
          url: this.getThumbnail(this.product.image, width, height),
          alt: this.product.name
        },
        desktop: {
          url: this.getThumbnail(this.product.image, width, height),
          alt: this.product.name
        }
      };
    },
    gallery () {
      return this.productGallery.map(imageObject => ({
        id: imageObject.id,
        mobile: {
          url: imageObject.src,
          alt: this.product.name
        },
        desktop: {
          url: imageObject.src,
          alt: this.product.name
        }
      }));
    },
    movingBottle () {
      return this.product.moved_video_url ? [{
        mobile: {
          url: this.product?.product_images?.moved_product_image || '',
          alt: this.product.name,
          vedio_url: this.product.moved_video_url,
          type: 'video'
        },
        desktop: {
          url: this.product?.product_images?.moved_product_image || '',
          alt: this.product.name,
          vedio_url: this.product.moved_video_url,
          type: 'video'
        }
      }] : []
    },
    availability () {
      return this.product.stock && this.product.stock.is_in_stock ? 'InStock' : 'OutOfStock'
    },
    sizeOption () {
      return get(this.productConfiguration, 'size', false)
    },
    giftRulesSkus () {
      const skus = this.product.parentSku ? [this.product.sku, this.product.parentSku] : [this.product.sku]
      return skus.map(sku => parseInt(sku))
    }
  },
  mounted () {
    if (this.isReadyToSendGTMEvents) {
      this.sendGtmEvent()
    }
    this.reviewsSku = this.product.parentSku
    window.addEventListener('scroll', this.onScroll);
  },
  watch: {
    $route () {
      this.reviewsSku = this.product.parentSku
    },
    isReadyToSendGTMEvents () {
      this.sendGtmEvent()
    }
  },
  methods: {
    ...mapActions('upsell-free-gifts', [
      'applyFreeGift'
    ]),
    ...mapActions('cart', [
      'setActionProcessing',
      'sync',
      'syncTotals'
    ]),
    sendGtmEvent () {
      this.$bus.$emit('pdp-before-enter', this.product)
    },
    handleAddToCartButtonChange ({ priceOnButton, strikeThroughPrice }) {
      this.priceOnButton = priceOnButton
      this.strikeThroughPrice = strikeThroughPrice > priceOnButton ? strikeThroughPrice : priceOnButton
    },
    afterProductAddToCart (shouldRedirectToCheckout) {
      this.$bus.$emit('upsell-add-to-cart')
      if (!shouldRedirectToCheckout) return
      this.$router.push(localizedRoute('/checkout'))
    },
    handleConfiguredProductValidation (result) {
      this.isConfiguredProductValid = result
    },
    setTier (value) {
      this.tier = value
      this.product.qty = value
    },
    onScroll () {
      if (isServer) return;
      var offset = document.getElementsByClassName('add-to-cart-top')[0];
      this.showButton = (offset !== undefined && window.pageYOffset > (offset.offsetTop + 200))
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.button-busy-green {
  ::v-deep {
    .sf-button {
        background: var(--c-mobileTopAddtocartButton-color);
      }
    }
}
.button-busy-primary {
  ::v-deep {
    .sf-button {
        background: var(--c-primary);
      }
    }
}
.o-product-details {
  &__mobile-add-to-cart-bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__add-to-cart--mobile-bottom {
    ::v-deep {
      .sf-button {
        --button-background: var(--c-mobileTopAddtocartButton-color);
        /* background-color: var(--c-mobileTopAddtocartButton-color); */
      }
    }
  }
  &__out-of-stock-error {
    color: var(--c-text-secondary);
  }
}
.o-product-details__rating {
  display: flex;
  justify-content: center;
  &-mobile {
    display: flex;
    justify-content: center;
    @include for_mobile {
      justify-content: start;
      padding: 0 10px;
    }
  }
  ::v-deep {
    .product__rating {
      padding: var(--spacer-sm);
      margin-top: var(--spacer-2xs);
      border-top: 1px solid var(--c-gray);
    }
  }
}
.product__order-info-icon {
  display: inline;
  margin-right: var(--spacer-xs);
}
.product__order-info-delivery-icon {
  ::v-deep {
    path {
      fill: transparent;
      stroke: var(--c-gray);
    }
  }
}
.additional-pruchase-info {
  display: flex;
  align-items: center;
  margin-right: var(--spacer-sm);
  font-size: var(--font-size--sm);
  color: var(--c-text-secondary);
  font-weight: var(--font-weight--semibold);
}
.product__description {
  color: #5f5f5f;
}
.product__description--mobile {
  margin: var(--spacer-xs) var(--spacer-sm) 0 var(--spacer-sm);
  text-align: center;
  @include for_desktop {
    display: none;
  }
  @include for-mobile {
    text-align: left;
    margin: 0;
  }
}
.product__description--desktop {
  text-align: center;
  @include for_mobile {
    display: none;
  }
}

.m-product-promotions {
  margin-top: var(--spacer-sm);
}
.delivery-and-shipping {
  margin: var(--spacer-xs) 0 var(--spacer-xs) var(--spacer-sm);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 2.5rem;
  @include for-mobile {
    justify-content: start;
    margin-left: 0;
  }
}
.promotion-product {
  width: 100%;
  height: 125px;
}
.o-product-details-wrapper {
  display: flex;
  flex-direction: column;
}
.product {
  @include for-desktop {
    display: flex;
    justify-content: center;
    padding: 0 var(--spacer-2xs) 0 var(--spacer-2xs);
  }
  &__info {
    margin: var(--spacer-2xs) auto var(--spacer-2xs);
    @include for-desktop {
      width: 100%;
      margin: 0 0 0 var(--spacer-sm);
    }
    @include for-mobile {
      padding: 0 10px;
      margin: 0;
    }
  }
  &__add-to-cart {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-self: start;

    &:last-child {
      margin-top: var(--spacer-sm);
      @include for-mobile {
        margin-top:10px;
      }
    }
    &__out-of-stock {
      margin: var(--spacer-sm) 0 var(--spacer-xs);
    }
    @include for-desktop {
      margin: var(--spacer-sm) 0 0 0;
    }
  }
  &__add-to-cart-wrapper {
    display: flex;
    margin: var(--spacer-2xs) var(--spacer-2xs) 0 var(--spacer-2xs);
    @include for-mobile {
      margin: 0;
      &.sticky-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        z-index: 999;
        transition: 0.3s;
        margin: 0 25px 20px;
        .product__add-to-cart {
          margin-top: 0;
        }
      }
    }
  }
  &__below-add-to-cart-block-wrapper {
    margin: var(--spacer-sm) var(--spacer-2xs) 0 var(--spacer-2xs);

    @include for-desktop {
      margin: var(--spacer-sm) 0 0 0;
    }
  }
  &__guide,
  &__compare,
  &__save {
    display: block;
    margin: var(--spacer-xl) 0 var(--spacer-base) auto;
  }
}
.wishlist-button {
  background-color: var(--c-wishlist-and-dropdown);
  height: auto;
  align-self: start;
  padding: 0;
  width: 4.25rem;
  margin: 0;
  @include for-desktop {
    --button-padding: var(--spacer-sm) var(--spacer-base);
    margin-top: var(--spacer-sm);
  }
  &__icon {
    margin: var(--button-padding, var(--spacer-sm) var(--spacer-base));
  }
}
.section {
  border-bottom: 1px solid #f1f2f3;
  padding-bottom: 10px;
  @include for-desktop {
    border: 0;
    padding-bottom: 0;
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.product-page {
  @include for-mobile {
    .default-layout {
      padding-bottom: 57px;
    }
  }
}
.product {
  &__add-to-cart-wrapper {
    .sf-button {
      border-radius: var(--spacer-xl);
      .sf-price {
        margin: 0;
      }
    }
    &.sticky-bottom {
      .sf-button {
        @include for-mobile {
          padding-top: 10px;
          padding-bottom: 10px;
          height: auto;
          border-radius: 35px;
          .icon {
            svg {
              padding-top: 4px;
            }
          }
        }
      }
    }
  }
}
.product__description {
  color: #5f5f5f;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.m-product-promotions {
  &:empty {
    margin: 0;
  }
}
</style>
