<template>
  <div class="m-product-options-configurable">
    <SfAlert
      v-if="product.errors && Object.keys(product.errors).length > 0"
      class="m-product-call-to-action__alert"
      :message="product.errors | formatProductMessages"
      type="danger"
    />
    <template v-for="attribute in productAttributes">
      <h2 :key="attribute.id" class="m-product-options-configurable__header">
        {{ attribute.label }}
      </h2>
      <MVisualSwatch
        data-test-type="configurable-product-attribute-swatch"
        :key="getKeyOfAttributeSwatch(attribute.attribute_code)"
        v-if="isSwatch(attribute.attribute_code)"
        :attribute-code="attribute.attribute_code"
        :options="availableOptions[attribute.attribute_code]"
        :selected-option="localConfiguration[attribute.attribute_code]? localConfiguration[attribute.attribute_code].id : null"
        :available-options="calculateSelectableOptionsOfCertainAttributeCode(attribute.attribute_code, currentlyPossibleOptions)"
        @change="handleChangeOption"
      />
      <ATextSwatch
        data-test-type="configurable-product-attribute-swatch"
        :key="getKeyOfAttributeSwatch(attribute.attribute_code)"
        v-else
        @change="handleChangeOption"
        :attribute-code="attribute.attribute_code"
        :options="availableOptions[attribute.attribute_code]"
        :selected-option="localConfiguration[attribute.attribute_code]? localConfiguration[attribute.attribute_code].id : null"
        :available-options="calculateSelectableOptionsOfCertainAttributeCode(attribute.attribute_code, currentlyPossibleOptions)"
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import reduce from 'lodash-es/reduce'
import map from 'lodash-es/map'
import every from 'lodash-es/every'
import mapValues from 'lodash-es/mapValues'
import { getAvailableFiltersByProduct } from '@vue-storefront/core/modules/catalog/helpers/filters'
import SfAlert from '@storefront-ui/vue/src/components/molecules/SfAlert/SfAlert.vue';
import ATextSwatch from '~/theme/components/atoms/a-text-swatch'
import MVisualSwatch from '~/theme/components/molecules/m-visual-swatch'
import flatMap from 'lodash-es/flatMap'
import { findConfigurableVariant, getConfigurationByChildProduct } from '@vue-storefront/core/modules/catalog/helpers/variant';
export default {
  name: 'MProductOptionsConfigurable',
  inject: {
    configurableOptionCallback: { default: false }
  },
  components: {
    SfAlert,
    ATextSwatch,
    MVisualSwatch
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    configuration: {
      type: Object,
      required: true
    },
    shouldPreselectConfiguration: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      localConfiguration: null
    }
  },
  created () {
    const currentProductSku = this.$route.params?.childSku
    const matchingChild = this.product?.configurable_children?.find(child => child.sku === currentProductSku) || null
    if (matchingChild) {
      this.localConfiguration = getConfigurationByChildProduct(
        this.product,
        matchingChild,
        this.availableOptions,
        this.getCurrentProductOptions
      )
      this.$emit('validation', true)
      if (this.configurableOptionCallback) {
        this.configurableOptionCallback({ ...this.localConfiguration, isCombined: true })
      }
      return
    }
    if (this.shouldPreselectConfiguration) {
      this.localConfiguration = this.createPreselectedConfiguration()
      this.$emit('validation', true)
      if (this.configurableOptionCallback) {
        this.configurableOptionCallback({ ...this.localConfiguration, isCombined: true })
      }
      return
    }
    this.localConfiguration = mapValues(this.configuration, entry => ({ id: null, label: null, type: entry.type }))
  },
  computed: {
    ...mapGetters({
      getCurrentProductOptions: 'product/getCurrentProductOptions',
      attributesByCode: 'attribute/getAttributeListByCode'
    }),
    productAttributes () {
      if (
        this.product.errors &&
        Object.keys(this.product.errors).length &&
        Object.keys(this.localConfiguration).length
      ) {
        return [];
      }
      return this.product.configurable_options;
    },
    availableOptions () {
      let options = getAvailableFiltersByProduct(this.product) || []
      let label = this.$i18n.t('Sold Out')
      const configurableChildren = this.product.configurable_children
      let newOptions = [];
      for (let attribute in this.productAttributes) {
        let code = this.productAttributes[attribute].attribute_code
        let values = options[code]
        if (typeof newOptions[code] === 'undefined') {
          newOptions[code] = []
        }
        for (let idx in values) {
          let value = values[idx]
          let children = configurableChildren.filter(child => (parseInt(child[code]) === parseInt(value.id) && child.stock.is_in_stock))
          value['is_in_stock'] = children.length
          value['label'] = (children.length) ? value['label'] : `(${label}) ` + value['label']
          newOptions[code].push(value)
        }
      }
      return newOptions
    },
    allPossibleCombinations () {
      const configurableChildren = this.product.configurable_children
      const configurableOptions = this.product.configurable_options
      const congfigurableAttributeCodes = configurableOptions.map(configurableAttribute => configurableAttribute.attribute_code)
      return map(configurableChildren, child => {
        return reduce(child, (childConfiguration, attributeValue, attributeCode) => {
          if (!congfigurableAttributeCodes.includes(attributeCode)) return childConfiguration
          childConfiguration.push({ type: attributeCode, value: attributeValue })
          return childConfiguration
        }, [])
      })
    },
    currentlyPossibleOptions () {
      return this.calcuatePossibleCombinations(this.localConfiguration)
    }
  },
  methods: {
    calculateCurrentlyVisibleSelection () {
      /*
        If product that's out of stock is selected in GUI, then the product is not really selected in the VSF
        This computed allows to see what user sees as selected even in such scenario.
       */
      return findConfigurableVariant({
        product: this.product,
        availabilityCheck: false,
        configuration: this.localConfiguration
      })
    },
    isOptionCompatibleWithConfiguration (option, configuration) {
      const attributeCode = option.type
      const possibleOptionsInCurrentIteration = this.calcuatePossibleCombinations(configuration)
      const selectableOptions = this.calculateSelectableOptionsOfCertainAttributeCode(attributeCode, possibleOptionsInCurrentIteration)
      return selectableOptions.includes(option.id)
    },
    getFirstAvailableOptionOfAttribute (attributeCode, configuration) {
      const possibleOptionsInCurrentIteration = this.calcuatePossibleCombinations(configuration)
      const selectableOptions = this.calculateSelectableOptionsOfCertainAttributeCode(attributeCode, possibleOptionsInCurrentIteration)
      const allPossibleOptions = this.availableOptions[attributeCode]
      const foundOption = allPossibleOptions.find(option => selectableOptions.includes(option.id))
      return foundOption || { id: null, label: null, type: attributeCode }
    },
    calcuatePossibleCombinations (currentConfiguration) {
      const configurableOptions = this.product.configurable_options
      const configurableAttributeCodes = configurableOptions.map(configurableAttribute => configurableAttribute.attribute_code)
      const possibleOptions = flatMap(configurableAttributeCodes, filteringAttribute => {
        return this.allPossibleCombinations.map(combination => {
          const isCombinationContainingFilteringAttribute = this.isCombinationContainingAttribute(combination, filteringAttribute)
          const canBeSelected = this.canCombinationBeSelected(configurableAttributeCodes, currentConfiguration, combination, filteringAttribute)
          if (isCombinationContainingFilteringAttribute) {
            /*
              When we select an attribute. We want to keep the other options of the same attribute available,
              but at the same time we don't want to enable other combinations that this attribute would enable
              so we create here a combination which allows for this attribute to be selected and doesn't allow
              anything more than that.
            */
            combination = this.stripCombinationOfAllAttributesExceptOne(combination, filteringAttribute)
          }
          return canBeSelected ? combination : []
        })
      })
      return possibleOptions
    },
    createPreselectedConfiguration () {
      const entries = Object.entries(this.configuration)
      let newLocalConfiguration = mapValues(this.configuration, entry => ({ id: null, label: null, type: entry.type }))
      for (let attributeIndex = 0; attributeIndex < entries.length; attributeIndex++) {
        const attributeCode = entries[attributeIndex][0]
        newLocalConfiguration[attributeCode] = this.getFirstAvailableOptionOfAttribute(attributeCode, newLocalConfiguration)
      }
      return newLocalConfiguration
    },
    stripCombinationOfAllAttributesExceptOne (combination, attributeCode) {
      return combination.map(attribute => {
        if (attributeCode !== attribute.type) return { type: null, value: NaN }
        return attribute
      })
    },
    canCombinationBeSelected (configurableAttributeCodes, currentConfiguration, combination, filteringAttribute) {
      return combination.every(attribute => {
        /*
          The requirement is to filter attributes in hierarchical manner.
          So first attribute should filter out the attributes below, but next attributes
          cannot filter out earlier ones.
       */
        if (!this.isAttributeFilterableByAnotherAttribute(configurableAttributeCodes, attribute.type, filteringAttribute)) {
          return true
        }
        if (attribute.type === filteringAttribute) {
          return true
        }
        if (!currentConfiguration[attribute.type] || !currentConfiguration[attribute.type].id) return true
        return parseInt(currentConfiguration[attribute.type].id) === attribute.value
      })
    },
    isAttributeFilterableByAnotherAttribute (configurableAttributeCodes, filteredAttribute, filteringAttribute) {
      return configurableAttributeCodes.indexOf(filteredAttribute) <= configurableAttributeCodes.indexOf(filteringAttribute)
    },
    isCombinationContainingAttribute (combination, attributeCode) {
      return combination.some(attribute => {
        if (attribute.type === attributeCode) return true
        return false
      })
    },
    getKeyOfAttributeSwatch (attributeCode) {
      if (!this.configuration[attributeCode]) return attributeCode
      return `${attributeCode}${!this.configuration[attributeCode].id}`
    },
    calculateSelectableOptionsOfCertainAttributeCode (attributeCode, possibleOptions) {
      return possibleOptions
        .map(possibleOption => {
          if (!possibleOption) return false // TODO remove
          const matchedEntry = possibleOption.find(attribute => attribute.type === attributeCode)
          if (!matchedEntry) return ''
          return possibleOption.find(attribute => attribute.type === attributeCode).value.toString()
        })
    },
    handleChangeOption (option) {
      if (option.length) {
        return
      }
      let selectedVariant = option.id ? option : { type: option.type, label: null, id: null }
      if (!this.isOptionCompatibleWithConfiguration(option, this.localConfiguration)) {
        selectedVariant = this.getFirstAvailableOptionOfAttribute(option.type, this.localConfiguration)
      }
      this.localConfiguration[option.type] = selectedVariant

      const isLocalConfigurationSet = every(this.localConfiguration, entry => entry && entry.id !== null)
      if (!isLocalConfigurationSet) {
        this.$emit('validation', false)
        return
      }
      this.$emit('validation', true)

      if (this.configurableOptionCallback) {
        this.configurableOptionCallback({ ...this.localConfiguration, isCombined: true })
      }
    },
    isSwatch (attributeCode) {
      const toParseData = this.attributesByCode[attributeCode]?.additional_data
      if (toParseData) {
        const parsedAdditionalData = JSON.parse(toParseData)
        return parsedAdditionalData.swatch_input_type && parsedAdditionalData.swatch_input_type === 'visual'
      }
      return false
    },
    getVisualSwatchOptionsMetadata (options, code) {
      const result = options.map(option => {
        return this.attributesByCode[code].options.find(metadata => metadata && option.id === metadata.value)
      })
      return result
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.m-product-options-configurable {
  display: flex;
  flex-wrap: wrap;
  &__header {
    text-align: center;
    width: 100%;
    font-size: 18px;
    @include for-mobile {
      display: none;
      .sf-select {
        padding: 0;
      }
    }
  }
}
.attribute {
  margin-bottom: var(--spacer-xl);
}
.product {
  &__select-size {
    flex: 100%;
    margin: 0 var(--spacer-sm);
    @include for-desktop {
      margin: 0;
    }
  }
  &__colors {
    display: flex;
    @include for-desktop {
      background-color: transparent;
      align-items: center;
    }
  }
  &__color-label {
    margin: 0 var(--spacer-lg) 0 0;
  }
  &__color {
    margin: 0 var(--spacer-sm);
    border-radius: 100%;
    padding: var(--spacer-sm);
    border: var(--color-picket-border);
  }
  &__color--selected {
    border: var(--color-picket-border-selected);
  }
}
</style>
<style lang="scss">
  @import "~@storefront-ui/shared/styles/helpers/breakpoints";
  .m-product-options-configurable {
    @include for-mobile {
      .sf-select {
        padding:10px 0 0 0;
        height: auto;
      }
    }
  }
</style>
