<template>
  <div class="visual-swatch-wrapper">
    <ASelect
      v-model="activeOption"
      @input="handleChangeOption"
    >
      <ASelectOption
        v-for="(option) in options"
        :value="option.id"
        :key="option.id"
        @change="handleChangeOption(option.id)"
      >
        {{ option.label }}
      </ASelectOption>
    </ASelect>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import AttributeSwatchSelector from '~/theme/mixins/attribute-swatch-selector.js';
import ASelect from '~/theme/components/atoms/a-select';
import ASelectOption from '~/theme/components/atoms/a-select-option';
import config from 'config'
export default {
  name: 'MVisualSwatch',
  mixins: [AttributeSwatchSelector],
  components: {
    ASelect,
    ASelectOption
  },
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/getAttributeListByCode'
    }),
    optionsMetadata () {
      return this.options.map(option => {
        return this.attributesByCode[this.attributeCode].options.find(metadata => metadata && option.id === metadata.value)
      })
    }
  },
  methods: {
    getButtonClasses (option, attributeCode) {
      return {
        'm-visual-swatch__option-image--active': this.isOptionSelected(option, attributeCode),
        'm-visual-swatch__option-image--disabled': this.isOptionDisabled(option)
      }
    },
    getButtonBackground (optionMetadata) {
      if (!optionMetadata || !optionMetadata.swatch || !optionMetadata.swatch.value) return { backgroundColor: '#dddddd' }
      if (optionMetadata && optionMetadata.swatch && optionMetadata.swatch.value[0] === '#') return { backgroundColor: optionMetadata.swatch.value }
      const backgroundUrl = `url(${config.images.visualSwatch}/${optionMetadata.swatch.value})`
      const normalizedBackgroundUrl = backgroundUrl.replace(/([^:])(\/\/)/g, '$1/')
      return { backgroundImage: normalizedBackgroundUrl }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.visual-swatch-wrapper {
  width: 100%;
}
.visual-swatch-wrapper {
  margin: var(--spacer-2xs) var(--spacer-xs) 0 var(--spacer-xs);
  @include for-mobile {
    margin: 0;
  }
}
.m-visual-swatch {
  --m-visual-swatch-option-size: 4rem;
  justify-content: center;
  width: 100%;
  z-index: 0;
  ::v-deep {
    .sf-color-picker__colors {
      align-items: flex-start;
      margin-left: calc(-1 * var(--spacer-sm));
      margin-bottom: calc(-1 * var(--spacer-lg));
      justify-content: center;
      margin-left: 0;
    }
  }
  &__option-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(2 * var(--spacer-sm) + var(--m-visual-swatch-option-size));
    min-width: min-content;
    margin-bottom: var(--spacer-lg);
    &--disabled {
      display: none;
    }
  }
  &__option-image {
    margin: 0 var(--spacer-sm) 0 var(--spacer-sm);
    width: var(--m-visual-swatch-option-size);
    height: var(--m-visual-swatch-option-size);
    background-position: left top;
    border-radius: 100%;
    display: flex;
    cursor: pointer;
    z-index: 0;
    box-sizing: border-box;
    border-style: none;
    border-width: 3px;
    background-size: cover;
    &--active {
      border-color: var(--c-primary);
      border-style: solid;
      background-position: -3px -3px;
    }
    &--disabled {
      cursor: auto;
    }
  }
  &__option-checkmark {
    width: 1em;
    height: 1em;
    background-color: var(--c-primary);
    border-radius: 100%;
    margin-left: auto;
  }
  &__option-label {
    margin: var(--spacer-sm) var(--spacer-xs) 0 var(--spacer-xs);
    word-break: normal;
    text-align: center;
    user-select: none;
  }
}
</style>
