<template>
  <div class="m-product-short-info">
    <div class="product__header">
      <h1 class="product__title">
        {{ product.name | htmlDecode }}
      </h1>
    </div>
  </div>
</template>
<script>
import { createSmoothscroll } from '~/theme/helpers'
export default {
  name: 'MProductShortInfo',
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    customOptions: {
      type: Object,
      default: () => ({})
    },
    reviews: {
      type: Array,
      default: () => []
    },
    scoreSummary: {
      type: Object,
      default: null
    }
  },
  methods: {
    openReviewsTab () {
      this.$store.commit('ui/setReviewProductTab', true);
      const reviewsEl = document.querySelector('#m-product-additional-info');
      if (!reviewsEl) return;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      createSmoothscroll(scrollTop, scrollTop + reviewsEl.getBoundingClientRect().top);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~@storefront-ui/shared/styles/helpers/typography";
.m-product-short-info {
  @include for-mobile {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .product__header {
      margin: 0;
    }
  }
}
.wishlist {
  margin-left: var(--spacer-xs);
}
.rating {
  @include for-mobile {
    display: none;
  }
  &__mobile {
    margin-left: var(--spacer-sm);
    @include for-desktop {
      display: none;
    }
  }
}
.product {
  &__title {
    font-size: var(--font-size--lg);
    font-weight: var(--font-weight--medium);
    color: #5f5f5f;
    letter-spacing: var(--spacer-2xs);
    margin: 1em 0;
    text-align: center;
    text-transform: uppercase;
    @include for-desktop {
      font-size: calc(var(--font-size--lg) * 1.25);
    }
  }
  &__header {
    margin: 0 var(--spacer-sm);
    @include for-desktop {
      margin: 0 auto;
    }
  }
  &__drag-icon {
    animation: moveicon 1s ease-in-out infinite;
  }
  &__price-and-rating {
    margin: var(--spacer-xs) var(--spacer-sm) var(--spacer-base);
    align-items: center;
    @include for-desktop {
      display: flex;
      justify-content: space-between;
      margin: var(--spacer-sm) 0 var(--spacer-lg) 0;
    }
  }
  &__description {
    color: var(--c-link);
    @include font(
      --product-description-font,
      var(--font-weight--light),
      var(--font-size--base),
      1.6,
      var(--font-family--primary)
    );
    @include for-mobile {
      padding-left: var(--spacer-sm);
      padding-right: var(--spacer-sm);
    }
  }
}
@keyframes moveicon {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 30%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
</style>
