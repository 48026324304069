<template>
  <div class="text-swatch-wrapper">
    <ASelect
      v-model="activeOption"
      @input="handleChangeOption"
    >
      <ASelectOption
        v-for="(option) in options"
        :value="option.id"
        :key="option.id"
        @change="handleChangeOption(option.id)"
      >
        {{ option.label }}
      </ASelectOption>
    </ASelect>
  </div>
</template>
<script>
import AttributeSwatchSelector from '~/theme/mixins/attribute-swatch-selector.js';
import ASelect from '~/theme/components/atoms/a-select';
import ASelectOption from '~/theme/components/atoms/a-select-option';

export default {
  name: 'ATextSwatch',
  components: {
    ASelect,
    ASelectOption
  },
  mixins: [AttributeSwatchSelector],
  methods: {
    getTextSwatchClassList (option) {
      return { 'button--active': this.isOptionSelected(option), 'button--disabled': this.isOptionDisabled(option) }
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/components/molecules/SfSelect.scss";
  .text-swatch-wrapper {
    width: 100%;
    margin: var(--spacer-2xs) var(--spacer-xs) 0 var(--spacer-xs);

    @include for-desktop {
      margin: 0;
    }
  }
  .a-text-swatch {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: var(--spacer-2xs) var(--spacer-xs) 0 var(--spacer-xs);

    @include for-mobile {
      justify-content: center;
    }
    &__button {
      border-radius: 5px;
      margin: var(--spacer-xs);
      font-size: var(--font-size--base);
      padding-bottom: var(--spacer-xs);
      padding-top: var(--spacer-xs);
      background-color: var(--c-white);
      border-style: solid;
      border-width: 1px;
      border-color: var(--c-gray);
      color: var(--c-primary);
      white-space: normal;
    }
    ::v-deep {
      .button--active {
        background-color: var(--c-primary);
        color: var(--c-white);
        cursor: pointer;
      }
      .button--disabled {
        border-color: var(--c-light-variant);
        color: var(--c-light-variant);
        display: none;
      }
    }
  }
</style>
