<template>
  <div class="buttons-wrapper">
    <SfButton
      v-for="option in options"
      :key="option.label"
      @click="inputHandler(option)"
      class="button"
      :class="{'button--disabled': disabled, 'button--active': currentOption === option.label}"
      :disabled="disabled"
    >
      <slot v-bind="{ option }">
        {{ option.label }}
      </slot>
    </SfButton>
  </div>
</template>
<script>
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
export default {
  name: 'AButtons',
  components: { SfButton },
  data () {
    return {
      currentOption: 0
    }
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    clickable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.currentOption = this.options[0].label
    this.$emit('change', this.options);
  },
  methods: {
    inputHandler (option) {
      if (!this.clickable) return
      if (this.currentOption && option.label === this.currentOption) {
        this.currentOption = null
        this.$emit('change', null);
        return
      }
      this.currentOption = option.label
      this.$emit('change', option);
    }
  }
};
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/shared/styles/components/molecules/SfSelect.scss";
  .button {
    border-radius: 5px;
    margin: var(--spacer-xs);
    font-size: var(--font-size--base);
    padding-bottom: var(--spacer-xs);
    padding-top: var(--spacer-xs);
    background-color: var(--c-white);
    border-style: solid;
    border-width: 1px;
    border-color: var(--c-gray);
    color: var(--c-white);
    &--active {
      background-color: var(--c-primary);
      color: var(--c-primary);
    }
    &--disabled {
      background-color: var(--c-light-variant);
      opacity: 0.3;
    }
  }
  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
</style>
